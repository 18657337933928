<template>
  <v-container>
    <h1>Comprobande de Informe Diario Facturación Electrónica</h1>
    <v-row class="mt-6">
      <v-col cols="6" sm="3" md="3">
        <v-text-field v-model="issueDate" label="Fecha" outlined type="date" dense />
      </v-col>
      <v-col cols="6" sm="3" md="3">
        <v-btn @click="get_docs" color="primary">Buscar</v-btn>
      </v-col>
      <v-col cols="6" sm="3" md="3">
        <v-btn text @click="generatePDF" color="info">Print</v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-6" ref="printArea">
      <v-col cols="12" md="12" v-if="seller.PartyIdentification">
        <v-data-table
          mobile-breakpoint="0"
          :headers="header_store"
          :items="[seller]"
          class="elevation-4"
          :loading="loading_status"
          loader-height="1"
          loading-text="Cargando ..."
          hide-default-footer
          dense
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-card flat>
          <v-card-title>
            Resolucion de Facturación Electrónica
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="header_resolution"
            :items="resolutions"
            class="elevation-4"
            :loading="loading_status"
            loader-height="1"
            loading-text="Cargando ..."
            hide-default-footer
            dense
          />
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-card-title> Recaudos de Venta </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="header_payments"
            :items="payments"
            :items-per-page="-1"
            class="elevation-4"
            item-key="id_pos,ean"
            :loading="loading_status"
            loader-height="1"
            loading-text="Cargando ..."
            hide-default-footer
            dense
          >
            <template v-slot:[`item.quantity`]="{ item }">
              {{ parseFloat(item.quantity).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.value`]="{ item }">
              $ {{ parseFloat(item.value).toLocaleString("es") }}
            </template>

            <template slot="body.append">
              <tr>
                <td>Total</td>
                <td class="text-right">{{ getTotal(payments, "quantity") }}</td>
                <td class="text-right">
                  {{ formatCurrency(getTotal(payments, "value")) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-card-title> Discriminacion de Ventas por Departamento </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="header_sales"
            :items="sales"
            :items-per-page="10"
            class="elevation-4"
            item-key="id_pos,ean"
            :loading="loading_status"
            loader-height="1"
            loading-text="Cargando ..."
            hide-default-footer
            dense
          >
            <template #top> </template>

            <template v-slot:[`item.taxes`]="{ item }">
              {{ "$ " + parseFloat(item.taxes).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ "$ " + parseFloat(item.price).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              {{ parseFloat(item.quantity).toLocaleString("es") }}
            </template>

            <template slot="body.append">
              <tr>
                <td>Total</td>
                <td class="text-right">{{ getTotal(sales, "quantity") }}</td>
                <td class="text-right">{{ formatCurrency(getTotal(sales, "taxes")) }}</td>
                <td class="text-right">{{ formatCurrency(getTotal(sales, "price")) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-card-title> Registros del al </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="header_invoices"
            :items="invoices"
            :items-per-page="-1"
            class="elevation-4"
            item-key="id_pos,ean"
            :loading="loading_status"
            loader-height="1"
            loading-text="Cargando ..."
            hide-default-footer
            dense
          >
            <template v-slot:[`item.subtotal`]="{ item }">
              {{ "$ " + parseFloat(item.subtotal).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.discount`]="{ item }">
              {{ "$ " + parseFloat(item.discount).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.taxes`]="{ item }">
              {{ "$ " + parseFloat(item.taxes).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ "$ " + parseFloat(item.price).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ "$ " + parseFloat(item.total).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.bags`]="{ item }">
              {{ "$ " + parseFloat(item.bags).toLocaleString("es") }}
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              {{ parseFloat(item.quantity).toLocaleString("es") }}
            </template>

            <template slot="body.append">
              <tr>
                <td>Total</td>
                <td class="text-right">
                  {{ formatCurrency(getTotal(invoices, "subtotal")) }}
                </td>
                <td class="text-right">
                  {{ formatCurrency(getTotal(invoices, "discount")) }}
                </td>
                <td class="text-right">
                  {{ formatCurrency(getTotal(invoices, "price")) }}
                </td>
                <td class="text-right">
                  {{ formatCurrency(getTotal(invoices, "taxes")) }}
                </td>
                <td class="text-right">
                  {{ formatCurrency(getTotal(invoices, "total")) }}
                </td>
                <td class="text-right">
                  {{ formatCurrency(getTotal(invoices, "bags")) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  name: "ReportDay",
  data() {
    return {
      loading_status: false,
      stores: [],
      store: "",
      issueDate: getToday(),
      payments: [],
      sales: [],
      invoices: [],
      resolutions: [],
      seller: {
        AddressLine: "",
        CityName: "",
        PartyIdentification: "",
        PartyIdentification_Id: "",
        PartyName: "",
        TaxLevelCode: "",
        client_email: "",
        client_phone: "",
        store_Caja: "",
        store_id: "",
        store_name: "",
      },
      header_store: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          filterable: true,
          value: "PartyName",
          dataType: "text",
        },
        {
          text: "Identificación",
          align: "center",
          sortable: true,
          filterable: true,
          value: "PartyIdentification",
          dataType: "text",
        },
        {
          text: "Dirección",
          align: "center",
          sortable: true,
          filterable: true,
          value: "AddressLine",
          dataType: "text",
        },
        {
          text: "Ciudad",
          align: "center",
          sortable: true,
          filterable: true,
          value: "CityName",
          dataType: "text",
        },
        {
          text: "Email",
          align: "center",
          sortable: true,
          filterable: true,
          value: "client_email",
          dataType: "text",
        },
        {
          text: "Telefono",
          align: "center",
          sortable: true,
          filterable: true,
          value: "client_phone",
          dataType: "text",
        },
      ],
      header_resolution: [
        {
          text: "Autorizacion",
          align: "start",
          sortable: true,
          filterable: true,
          value: "InvoiceAuthorization",
          dataType: "text",
        },
        {
          text: "Prefijo",
          align: "center",
          sortable: true,
          filterable: true,
          value: "Prefix",
          dataType: "number",
        },
        {
          text: "Desde",
          align: "center",
          sortable: true,
          filterable: true,
          value: "AuthorizationFrom",
          dataType: "number",
        },
        {
          text: "Hasta",
          align: "center",
          sortable: true,
          filterable: true,
          value: "AuthorizationTo",
          dataType: "number",
        },
        {
          text: "Desde",
          align: "center",
          sortable: true,
          filterable: true,
          value: "StartDate",
        },
        {
          text: "Hasta",
          align: "center",
          sortable: true,
          filterable: true,
          value: "EndDate",
        },
      ],
      header_sales: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          filterable: true,
          value: "category",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          filterable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Impuesto",
          align: "end",
          sortable: true,
          filterable: true,
          value: "taxes",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          filterable: true,
          value: "price",
          dataType: "number",
        },
      ],
      header_invoices: [
        {
          text: "Comprobante",
          align: "start",
          sortable: true,
          filterable: true,
          value: "invoice",
          dataType: "text",
        },
        {
          text: "Subtoal",
          align: "end",
          sortable: true,
          filterable: true,
          value: "subtotal",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          filterable: true,
          value: "discount",
          dataType: "number",
        },
        {
          text: "Base Gravable",
          align: "end",
          sortable: true,
          filterable: true,
          value: "price",
          dataType: "number",
        },
        {
          text: "Impuesto",
          align: "end",
          sortable: true,
          filterable: true,
          value: "taxes",
          dataType: "number",
        },
        {
          text: "Total",
          align: "end",
          sortable: true,
          filterable: true,
          value: "total",
          dataType: "number",
        },
        {
          text: "Imp.Bolsas",
          align: "end",
          sortable: true,
          filterable: true,
          value: "bags",
          dataType: "number",
        },
      ],
      header_payments: [
        {
          text: "Forma de Pago",
          align: "start",
          sortable: true,
          filterable: true,
          value: "type",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          filterable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          filterable: true,
          value: "value",
          dataType: "number",
        },
      ],
      docs: [],
    };
  },
  methods: {
    printDiv() {
      const printContents = this.$refs.printArea.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    get_stores() {
      var qry = {};
      qry.store = window.profile.store_id;
      this.loading_status = true;
      webserver("get_pos", qry, (data) => {
        this.loading_status = false;
        if (data) {
          data.forEach((item) => {
            item.codigo = item.store_id;
            item.valor = item.store_name;
          });
        }
        this.stores = data;
      });
    },
    getTotal(items, key) {
      return items.reduce((sum, item) => sum + parseFloat(item[key]), 0);
    },
    get_docs() {
      const qry = {
        store: window.profile.store_id,
        store_id: window.profile.store_id,
        issueDate: this.issueDate,
        filters: [],
      };

      this.loading_status = true;
      console.log(qry);
      webserver("sales_report_fe", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        this.sales = data.sales;
        this.invoices = data.invoices;
        this.payments = data.payments;
        this.resolutions = data.resolutions;
        this.seller = data.seller;
      });
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
    reorderData(dataArray, order) {
      return dataArray.map((data) => {
        let reorderedData = {};
        order.forEach((key) => {
          reorderedData[key] = data[key];
        });
        return reorderedData;
      });
    },
    generatePDF() {
      // Obtener los datos de las tablas como antes
      var catagorySales = [...this.sales];
      catagorySales.forEach((item) => {
        //item.quantity = parseInt(item.quantity);
        item.taxes = parseInt(item.taxes);
        item.price = parseInt(item.price);
      });

      catagorySales.push({
        category: "Totales",
        quantity: this.getTotal(this.sales, "quantity").toString(),
        taxes: this.getTotal(this.sales, "taxes"),
        price: this.getTotal(this.sales, "price"),
      });

      var pagos = [...this.payments];

      pagos.forEach((item) => {
        // item.quantity = parseInt(item.quantity);
        item.value = parseInt(item.value);
      });
      pagos.push({
        type: "Totales",
        quantity: this.getTotal(this.payments, "quantity").toString(),
        value: this.getTotal(this.payments, "value"),
      });

      this.invoices.forEach((item) => {
        item.subtotal = parseInt(item.subtotal);
        item.discount = parseInt(item.discount);
        item.price = parseInt(item.price);
        item.taxes = parseInt(item.taxes);
        item.total = parseInt(item.total);
        item.bags = parseInt(item.bags);
      });

      var tableHeaders = this.header_invoices.map((header) => header.value);
      var ventas = this.reorderData(this.invoices, tableHeaders);

      ventas.push({
        invoice: "Totales",
        subtotal: this.getTotal(this.invoices, "subtotal"),
        discount: this.getTotal(this.invoices, "discount"),
        price: this.getTotal(this.invoices, "price"),
        taxes: this.getTotal(this.invoices, "taxes"),
        total: this.getTotal(this.invoices, "total"),
        bags: this.getTotal(this.invoices, "bags"),
      });

      const pdf = new jsPDF("p", "mm", "a4");
      let currentY = 10; // Coordenada Y inicial para la primera tabla
      // Añadir el título del reporte y la fecha de impresión
      const reportTitle = "Comprobande de Informe Diario Facturación Electrónica";
      const printDate = `Fecha de impresión: ${new Date().toLocaleDateString("es-CO")}`;

      pdf.setFontSize(16);
      pdf.text(reportTitle, 10, 10);
      pdf.setFontSize(10);
      pdf.text(printDate, 10, 20);
      currentY = 30; // Coordenada Y inicial para la primera tabla
      const tables = [
        {
          headers: this.header_store || [],
          items: [this.seller] || [],
          title: "Información de la Tienda",
        },
        {
          headers: this.header_resolution || [],
          items: this.resolutions || [],
          title: "Resolucion de Facturación Electrónica",
        },
        {
          headers: this.header_sales || [],
          items: catagorySales || [],
          title: "Discriminacion de Ventas por Departamento",
        },

        {
          headers: this.header_payments || [],
          items: pagos || [],
          title: "Recaudos de Venta",
        },
        {
          headers: this.header_invoices || [],
          items: ventas || [],

          title: "Registros del al",
        },
      ];

      tables.forEach((table, index) => {
        if (index > 0) {
          currentY += 10; // Añadir espacio entre tablas
        }

        // Añadir título con estilo personalizado
        pdf.setFontSize(10); // Tamaño de fuente del título
        pdf.text(table.title, 10, currentY);

        const tableData = table.items.map((item) => {
          return Object.values(item).map((value) => {
            return typeof value === "number" ? this.formatCurrency(value) : value;
          });
        });

        const tableHeaders = table.headers.map((header) => header.text);

        // Determinar las columnas que son numéricas
        const numericColumns = [];
        if (table.items.length > 0) {
          Object.keys(table.items[0]).forEach((key, colIndex) => {
            if (typeof table.items[0][key] === "number") {
              numericColumns.push(colIndex);
            }
          });
        }

        const columnStyles = {};
        numericColumns.forEach((colIndex) => {
          columnStyles[colIndex] = { halign: "right" };
        });

        pdf.autoTable({
          startY: currentY + 5, // Añadir un espacio antes de empezar la tabla
          head: [tableHeaders],
          body: tableData,
          theme: "grid", // Puedes cambiar el tema si deseas
          styles: { cellPadding: 2, fontSize: 8 },
          headStyles: { fontSize: 8, fillColor: [95, 149, 140] },
          footStyles: { fontSize: 8, fillColor: [44, 238, 144] },
          columnStyles: columnStyles,
          didDrawPage: (data) => {
            currentY = data.cursor.y; // Actualizar la coordenada Y para la próxima tabla
          },
        });
      });

      var fileName = this.store + "-" + this.issueDate + ".pdf";
      pdf.save(fileName);
    },
  },
  mounted() {
    this.get_docs();
  },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>
